import React, { memo, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';

// Pre-carga de imágenes
const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

// Variantes de animación para el logo
const logoVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
      ease: 'easeOut',
    },
  },
};

// Configuración de temas
const lightThemeConfig = {
  background: '#F5F5F0',
  logoColor: '/assets/images/logo-turquesa.png',
};
const darkThemeConfig = {
  background: '#002B36',
  logoColor: '/assets/images/logo-blanco.png',
};

const MainHero = memo(() => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  // Pre-cargar imágenes al montar el componente
  useEffect(() => {
    const imagesToPreload = [
      '/assets/images/logo-turquesa.webp',
      '/assets/images/logo-blanco.webp',
    ];
    preloadImages(imagesToPreload);
  }, []);

  const colors = isDarkMode ? darkThemeConfig : lightThemeConfig;
  const brandColor = '#008080';

  // Función para hacer scroll suave a una sección
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box
      component="section"
      sx={{
        position: 'relative',
        minHeight: '100vh',
        backgroundColor: colors.background,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        overflow: 'hidden',
        gap: 4,
      }}
    >
      {/* Logo animado */}
      <Box
        component={motion.div}
        variants={logoVariants}
        initial="hidden"
        animate="visible"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          willChange: 'transform, opacity',
        }}
      >
        <img
          src={colors.logoColor}
          alt="Logo Enigma"
          loading="eager"
          style={{
            width: '150px',
            height: 'auto',
          }}
        />
      </Box>

      {/* Llamadas a la acción */}
      <Box
        component="nav"
        aria-label="Llamadas a la acción"
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 2,
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={() => scrollToSection('nuestraCarta')}
          variant="contained"
          sx={{
            backgroundColor: brandColor,
            color: '#fff',
            fontWeight: 600,
            px: 4,
            py: 1.5,
            borderRadius: '999px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: `${brandColor}E6`,
            },
          }}
        >
          Ver Menú
        </Button>
        <Button
          onClick={() => scrollToSection('reservationForm')}
          variant="outlined"
          sx={{
            borderColor: brandColor,
            color: isDarkMode ? '#fff' : '#000',
            fontWeight: 600,
            px: 4,
            py: 1.5,
            borderRadius: '999px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: `${brandColor}1A`,
            },
          }}
        >
          Reservar
        </Button>
      </Box>
    </Box>
  );
});

export default MainHero;
