import React from 'react';
import { Box, useTheme } from '@mui/material';

const SectionDivider = ({ backgroundColor, height = '100px', invert = false }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height,
        backgroundColor: backgroundColor || theme.palette.background.default,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: invert ? 'auto' : 0,
          bottom: invert ? 0 : 'auto',
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.background.default,
          clipPath: invert
            ? 'ellipse(150% 100% at 50% 100%)'
            : 'ellipse(150% 100% at 50% 0%)',
        },
      }}
    />
  );
};

export default SectionDivider;
