import { createTheme } from '@mui/material/styles';

const getTheme = (mode) =>
  createTheme({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            primary: { main: '#237584', light: '#4CA5A4', dark: '#185151' },
            secondary: { main: '#9FB289', light: '#C1D2A9', dark: '#6E8E60' },
            error: { main: '#CB5910', light: '#E97A3B', dark: '#933D0A' },
            background: { default: '#F5F5F0', paper: '#FFFFFF' },
            text: { primary: '#294853', secondary: '#6A93A2', contrast: '#FFFFFF' },
          }
        : {
            primary: { main: '#297373', light: '#3A8F8F', dark: '#1A5353' }, // Verde azulado elegante
            secondary: { main: '#F4A261', light: '#F7B992', dark: '#E4761C' }, // Naranja cálido
            error: { main: '#E63946', light: '#FF6F71', dark: '#A61B23' }, // Rojo profundo
            background: { default: '#1B262C', paper: '#2C3E50' }, // Azul oscuro sobrio
            text: { primary: '#ECEFF4', secondary: '#A9B4C3', contrast: '#1B262C' }, // Blanco cálido y gris claro
          }),
    },
    typography: {
      fontFamily: "'Calathea', sans-serif", // Aquí es donde se aplica la fuente Calathea
      h1: {
        fontFamily: "'Calathea', sans-serif", // Fuente personalizada para h1
        fontWeight: 700,
        fontSize: '3rem',
        color: mode === 'light' ? '#237584' : '#297373',
        transition: 'color 0.3s ease',
      },
      h2: {
        fontFamily: "'Calathea', sans-serif", // Fuente personalizada para h2
        fontWeight: 400,
        fontSize: '2rem',
        color: mode === 'light' ? '#294853' : '#ECEFF4',
        transition: 'color 0.3s ease',
      },
      button: {
        textTransform: 'capitalize',
        fontWeight: 600,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            margin: 0,
            padding: 0,
            transition: 'background-color 0.3s ease, color 0.3s ease',
            backgroundColor: mode === 'light' ? '#F5F5F0' : '#1B262C',
            color: mode === 'light' ? '#294853' : '#ECEFF4',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
              boxShadow: mode === 'light'
                ? '0 0 8px #4CA5A4, 0 0 16px #4CA5A4'
                : '0 0 8px #297373, 0 0 16px #297373',
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'light' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(27, 38, 44, 0.9)',
            backdropFilter: 'blur(10px)',
            boxShadow: 'none',
            transition: 'background-color 0.3s ease',
          },
        },
      },
    },
  });

export default getTheme;
