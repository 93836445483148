import React, { useEffect } from 'react';
import { ThemeProviderCustom } from './ThemeContext';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiesConsent from './components/CookiesConsent';
import { useTranslation } from 'react-i18next';
import './i18n'; // Asegúrate de inicializar i18n correctamente

// Este componente se encargará de hacer scroll a la parte superior al cambiar de ruta
const ScrollToTop = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
};

const App = () => {
  const { t } = useTranslation(); // Hook de i18next para traducir

  return (
    <ThemeProviderCustom>
      <Router>
        <ScrollToTop>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          </Routes>
          <Footer />
          <CookiesConsent /> {/* Popup de cookies */}
        </ScrollToTop>
      </Router>
    </ThemeProviderCustom>
  );
};

export default App;
