import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  Grid,
  MenuItem,
} from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTheme } from '@mui/material/styles';

const steps = ['Información Personal', 'Detalles de la Reserva', 'Confirmación'];
const specialOptions = [
  { value: '', label: 'Ninguna' },
  { value: 'menuDegustacion', label: 'Menú Degustación' },
  { value: 'cenaFamiliar', label: 'Cena Familiar' },
  { value: 'eventoEmpresa', label: 'Evento Empresa' },
  { value: 'cenaRomantica', label: 'Cena Romántica' },
  { value: 'aniversario', label: 'Aniversario' },
  { value: 'cumpleanos', label: 'Cumpleaños' },
];

const ReservationForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    date: '',
    time: '',
    guests: '',
    specialRequests: '',
  });
  const [errors, setErrors] = useState({});
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });
  const theme = useTheme();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const imageVariants = {
    hidden: { opacity: 0, y: '-100vh', rotate: 0, scale: 0.5 },
    visible: {
      opacity: 1,
      y: '80%',
      rotate: 180,
      scale: 1,
      transition: { duration: 2, ease: 'easeOut' },
    },
  };

  const validateStep = () => {
    let newErrors = {};
    if (activeStep === 0) {
      if (!/^[a-zA-Z\s]+$/.test(formData.name)) {
        newErrors.name = 'Introduce un nombre válido.';
      }
      if (!/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = 'Introduce un correo electrónico válido.';
      }
    } else if (activeStep === 1) {
      if (!formData.date || new Date(formData.date) < new Date()) {
        newErrors.date = 'Introduce una fecha válida.';
      }
      if (!formData.time) {
        newErrors.time = 'Selecciona una hora.';
      }
      if (formData.guests < 1 || formData.guests > 20) {
        newErrors.guests = 'La cantidad de personas debe estar entre 1 y 20.';
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      if (activeStep < steps.length - 1) {
        setActiveStep(prev => prev + 1);
      } else {
        const basePhoneNumber = '34672796006';
        // Mensaje para el cliente que envía su solicitud de reserva
        const mensaje =
          `Hola! 👋\n\n` +
          `Quisiera reservar una mesa. Aquí mis datos:\n` +
          `Nombre: ${formData.name}\n` +
          `Correo: ${formData.email}\n` +
          `Fecha: ${formData.date}\n` +
          `Hora: ${formData.time}\n` +
          `Personas: ${formData.guests}\n` +
          `Peticiones Especiales: ${formData.specialRequests ? specialOptions.find(opt => opt.value === formData.specialRequests)?.label : 'Ninguna'}\n\n` +
          `Gracias, espero su confirmación.`;

        window.open(
          `https://api.whatsapp.com/send?phone=${basePhoneNumber}&text=${encodeURIComponent(mensaje)}`,
          '_blank'
        );
      }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(prev => prev - 1);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                label="Nombre"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Correo Electrónico"
                name="email"
                value={formData.email}
                onChange={handleChange}
                type="email"
                fullWidth
                required
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Fecha"
                name="date"
                value={formData.date}
                onChange={handleChange}
                type="date"
                fullWidth
                required
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                error={!!errors.date}
                helperText={errors.date}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Hora"
                name="time"
                value={formData.time}
                onChange={handleChange}
                select
                fullWidth
                required
                variant="outlined"
                error={!!errors.time}
                helperText={errors.time}
              >
                {['12:00 PM', '1:00 PM', '2:00 PM', '6:00 PM', '7:00 PM', '8:00 PM'].map((time) => (
                  <MenuItem key={time} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Cantidad de Personas"
                name="guests"
                value={formData.guests}
                onChange={handleChange}
                type="number"
                fullWidth
                required
                variant="outlined"
                inputProps={{ min: 1, max: 20 }}
                error={!!errors.guests}
                helperText={errors.guests}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Peticiones Especiales"
                name="specialRequests"
                value={formData.specialRequests}
                onChange={handleChange}
                select
                fullWidth
                variant="outlined"
                helperText="Selecciona si deseas incluir una petición especial o deja 'Ninguna' si no."
              >
                {specialOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Box textAlign="center">
            <Typography variant="h6" gutterBottom>
              ¡Estás a un paso de enviar tu reserva!
            </Typography>
            <Typography variant="body1">
              Al presionar "Confirmar Reserva" se enviará tu solicitud de reserva.
            </Typography>
          </Box>
        );
      default:
        return <div>Paso desconocido</div>;
    }
  };

  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
        background: theme.palette.background.default,
        padding: theme.spacing(10, 4),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 500px)': {
          paddingBottom: '350px',
        },
      }}
    >
      <motion.img
        src="/assets/images/cordero.png"
        alt="Plato de Cordero"
        initial="hidden"
        animate={controls}
        variants={imageVariants}
        style={{
          position: 'absolute',
          zIndex: 1,
          width: '250px',
          height: 'auto',
          bottom: '30%',
          left: '5%',
        }}
      />

      <Box
        sx={{
          maxWidth: '800px',
          margin: '0 auto',
          padding: theme.spacing(5, 4),
          background: theme.palette.background.paper,
          borderRadius: theme.shape.borderRadius * 2,
          boxShadow: theme.shadows[4],
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mb: theme.spacing(4),
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.main,
          }}
        >
          Reserva Tu Mesa
        </Typography>

        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: theme.spacing(5) }}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderStepContent(activeStep)}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: theme.spacing(6) }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            variant="outlined"
            sx={{ color: theme.palette.secondary.main, borderColor: theme.palette.secondary.main }}
          >
            Atrás
          </Button>
          <Button
            onClick={handleNext}
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }}
          >
            {activeStep === steps.length - 1 ? 'Confirmar Reserva' : 'Siguiente'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ReservationForm;
