import React, { useState, useEffect, useMemo } from "react";
import {
  GoogleMap,
  LoadScript,
  DirectionsRenderer,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import LocalParkingIcon from "@mui/icons-material/LocalParking"; // Icono de parking
import RestaurantIcon from "@mui/icons-material/Restaurant"; // Icono de restaurante

const MapWithRoutes = () => {
  const [directionsResponses, setDirectionsResponses] = useState([]);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);

  // Coordenadas memoizadas
  const parking1 = useMemo(
    () => ({ lat: 38.644952339156596, lng: 0.04218831056790957 }),
    []
  );
  const parking2 = useMemo(() => ({ lat: 38.641872, lng: 0.043513 }), []);
  const enigma = useMemo(
    () => ({ lat: 38.64516018989208, lng: 0.044224993269773 }),
    []
  );

  const links = {
    parking1:
      "https://www.google.com/maps/dir//Mercadillo+Frutas+y+Verduras+de+Calpe.,+Parking+Casco+Antiguo,+03710+Calp,+Alicante,+Espa%C3%B1a",
    parking2:
      "https://www.google.com/maps/dir//CONSULTORIO+M%C3%89DICO+CASA+DEL+MAR,+Partida+Manzanera,+7,+03710+Calp,+Alicante,+Espa%C3%B1a",
    enigma:
      "https://www.google.com/maps/dir//Enigma+Cocina+con+Alma,+Carrer+campanario,+5,+03710+Calp,+Alicante,+Espa%C3%B1a",
  };

  // Función para obtener direcciones
  const fetchDirections = async (origin, destination) => {
    const directionsService = new window.google.maps.DirectionsService();
    try {
      const result = await directionsService.route({
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.WALKING,
      });
      return result;
    } catch (error) {
      console.error("Error al obtener direcciones:", error);
      return null;
    }
  };

  useEffect(() => {
    if (!googleMapsLoaded) return;

    const fetchAllRoutes = async () => {
      const result1 = await fetchDirections(parking1, enigma);
      const result2 = await fetchDirections(parking2, enigma);

      setDirectionsResponses([result1, result2].filter(Boolean));
    };

    fetchAllRoutes();
  }, [googleMapsLoaded, parking1, parking2, enigma]);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyCF5HzV0pVkk27aGenijRfxOvwxThVUab0" // Reemplaza con tu API Key válida
      onLoad={() => setGoogleMapsLoaded(true)}
      onError={() => console.error("Error al cargar Google Maps API")}
    >
      {googleMapsLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "500px",
            borderRadius: "12px",
          }}
          center={enigma}
          zoom={16}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
          }}
        >
          {/* Renderizar las rutas */}
          {directionsResponses.map((response, index) => (
            <DirectionsRenderer
              key={index}
              options={{
                directions: response,
                suppressMarkers: true,
                polylineOptions: {
                  strokeColor: index === 0 ? "#45788B" : "#798C45",
                  strokeWeight: 5,
                },
              }}
            />
          ))}

          {/* Indicador para Parking 1 */}
          <Marker
            position={parking1}
            onClick={() =>
              setSelectedPlace({
                type: "Parking 1",
                location: parking1,
                link: links.parking1,
              })
            }
            icon={{
              path: LocalParkingIcon,
              fillColor: "#45788B",
              fillOpacity: 1,
              scale: 1,
              strokeWeight: 1,
            }}
          />

          {/* Indicador para Parking 2 */}
          <Marker
            position={parking2}
            onClick={() =>
              setSelectedPlace({
                type: "Parking 2",
                location: parking2,
                link: links.parking2,
              })
            }
            icon={{
              path: LocalParkingIcon,
              fillColor: "#45788B",
              fillOpacity: 1,
              scale: 1,
              strokeWeight: 1,
            }}
          />

          {/* Indicador para el Restaurante Enigma */}
          <Marker
            position={enigma}
            onClick={() =>
              setSelectedPlace({
                type: "Restaurante Enigma",
                location: enigma,
                link: links.enigma,
              })
            }
            icon={{
              path: RestaurantIcon,
              fillColor: "#FF6347",
              fillOpacity: 1,
              scale: 1,
              strokeWeight: 1,
            }}
          />

          {/* InfoWindow */}
          {selectedPlace && (
            <InfoWindow
              position={selectedPlace.location}
              onCloseClick={() => setSelectedPlace(null)}
            >
              <div>
                <h4>{selectedPlace.type}</h4>
                <a
                  href={selectedPlace.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    style={{
                      padding: "10px",
                      borderRadius: "8px",
                      border: "none",
                      backgroundColor: "#45788B",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    Ver en Google Maps
                  </button>
                </a>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      ) : (
        <p>Cargando mapa...</p>
      )}
    </LoadScript>
  );
};

export default MapWithRoutes;
