import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useThemeContext } from '../ThemeContext';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { toggleTheme, mode } = useThemeContext();
  const location = useLocation();
  const { i18n, t } = useTranslation(); // Usamos el hook useTranslation
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLanguageClick = (event) => setAnchorEl(event.currentTarget);
  const handleLanguageClose = () => setAnchorEl(null);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setAnchorEl(null);
  };

  const isLightMode = mode === 'light';

  // Elementos de navegación traducidos
  const navItems = [
    { label: t('header.nav.menu.carta'), route: '/', state: { scrollTo: 'nuestraCarta' } },
    { label: t('header.nav.menu.reservas'), route: '/', state: { scrollTo: 'reservationForm' } },
    { label: t('header.nav.menu.contacto'), route: '/about' },
  ];

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <AppBar
      position="sticky"
      elevation={2}
      sx={{
        backgroundColor: isLightMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 43, 54, 0.8)',
        backdropFilter: 'blur(12px)',
        borderRadius: '0 0 16px 16px',
        borderBottom: `1px solid ${isLightMode ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.2)'}`,
        p: { xs: '8px 16px', md: '0 32px' },
        transition: 'background-color 0.3s ease, border-radius 0.3s ease',
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box
          component={RouterLink}
          to="/"
          sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
        >
          <img
            src={isLightMode ? '/assets/images/logo.png' : '/assets/images/logo-white.png'}
            alt="Enigma Logo"
            style={{ height: '40px', cursor: 'pointer' }}
          />
        </Box>

        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 3 }}>
          {navItems.map((item, index) => {
            if (location.pathname === '/' && item.state && item.state.scrollTo) {
              return (
                <Button
                  key={index}
                  onClick={() => scrollToSection(item.state.scrollTo)}
                  sx={{
                    color: isLightMode ? 'primary.main' : 'secondary.main',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                  }}
                >
                  {item.label}
                </Button>
              );
            }
            return (
              <Button
                key={index}
                component={RouterLink}
                to={item.route}
                state={item.state}
                sx={{
                  color: isLightMode ? 'primary.main' : 'secondary.main',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                }}
              >
                {item.label}
              </Button>
            );
          })}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
          <IconButton
            onClick={toggleTheme}
            sx={{ color: 'primary.main', '&:hover': { color: 'secondary.main' } }}
          >
            {isLightMode ? <Brightness4Icon /> : <Brightness7Icon />}
          </IconButton>

          <IconButton
            onClick={handleLanguageClick}
            sx={{ color: 'primary.main', '&:hover': { color: 'secondary.main' } }}
          >
            <TranslateIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleLanguageClose}
            sx={{
              mt: 1.5,
              '& .MuiPaper-root': {
                backgroundColor: isLightMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 43, 54, 0.7)',
                backdropFilter: 'blur(10px)',
                borderRadius: '12px',
                boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.3s ease',
                color: isLightMode ? '#294853' : '#FFFFFF',
                py: 1,
              },
              '& .MuiMenuItem-root': {
                fontSize: '1rem',
                fontWeight: 500,
                px: 2,
                borderRadius: '8px',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                '&:hover': {
                  backgroundColor: isLightMode
                    ? 'rgba(0, 0, 0, 0.05)'
                    : 'rgba(255, 255, 255, 0.1)',
                },
              },
            }}
          >
            <MenuItem onClick={() => handleLanguageChange('es')}>Español</MenuItem>
            <MenuItem onClick={() => handleLanguageChange('en')}>English</MenuItem>
            <MenuItem onClick={() => handleLanguageChange('fr')}>Français</MenuItem>
            <MenuItem onClick={() => handleLanguageChange('de')}>Deutsch</MenuItem>
            <MenuItem onClick={() => handleLanguageChange('nl')}>Nederlands</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
