import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab, Grid, Card, CardContent, CardMedia } from '@mui/material';
import { motion } from 'framer-motion';
import ModalCarta from './ModalCarta';
import CategoryDetails from './CategoryDetails';  // Asegúrate de que esta importación sea correcta

const NuestraCarta = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ title: '', subcategories: [] });

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCardClick = (title) => {
    const subcategories = CategoryDetails[title] || [];
    setModalData({ title, subcategories });
    setModalOpen(true);
  };

  // Datos organizados para las categorías
  const carta = [
    { title: 'Entrantes', description: 'Una combinación única de sabores.', image: '/assets/images/cocas.png' },
    { title: 'Plato Principal', description: 'Descubre nuestra cocina fusión sin igual.', image: '/assets/images/canelon.jpg' },
    { title: 'Postres Caseros', description: 'Un dulce final para tu experiencia.', image: '/assets/images/postre.jpg' },
  ];

  const bebidas = [
    { title: 'Cócktails Enigma', description: 'Una mezcla refrescante de sabores.', image: '/assets/images/drink.jpg' },
    { title: 'Vinos', description: 'Selección premium de vinos.', image: '/assets/images/vino.jpg' },
    { title: 'Café y Licores', description: 'Perfecto para cerrar la velada.', image: '/assets/images/cafe.jpg' },
  ];

  const menus = [
    { title: 'Menú Degustación', description: 'Una experiencia completa.', image: '/assets/images/degustacion.jpg' },
    { title: 'Menú Infantil', description: 'Pensado para los más pequeños.', image: '/assets/images/infantil.jpg' },
    { title: 'Menú Especial', description: 'Sabores sin límites.', image: '/assets/images/especial.jpg' },
  ];

  // Renderiza cada conjunto de elementos
  const renderItems = (items) => (
    <Grid container spacing={4} justifyContent="center">
      {items.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            style={{ height: '100%' }}
            onClick={() => handleCardClick(item.title)}  // Llamada a handleCardClick
          >
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)',
                borderRadius: '15px',
                overflow: 'hidden',
                transition: 'transform 0.3s ease',
                cursor: 'pointer',
              }}
            >
              <CardMedia component="img" image={item.image} alt={item.title} height="200" />
              <CardContent
                sx={{
                  textAlign: 'center',
                  padding: '20px',
                }}
              >
                <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                  {item.title}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Box
      sx={{
        padding: '60px 20px',
        backgroundColor: 'background.default',
        textAlign: 'center',
      }}
    >
      {/* Título Principal */}
      <Typography
        variant="h4"
        sx={{
          mb: 4,
          fontWeight: 'bold',
          color: 'primary.main',
          textTransform: 'uppercase',
        }}
      >
        Nuestra Carta
      </Typography>

      {/* Tabs para cambiar de sección */}
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        centered
        sx={{
          mb: 4,
          '& .MuiTab-root': {
            textTransform: 'none',
            fontWeight: 'bold',
            color: 'text.secondary',
          },
          '& .Mui-selected': {
            color: 'primary.main',
          },
          '& .MuiTabs-indicator': {
            backgroundColor: 'primary.main',
          },
        }}
      >
        <Tab label="Carta" />
        <Tab label="Bebidas" />
        <Tab label="Menús" />
      </Tabs>

      {/* Contenido dinámico basado en la tab seleccionada */}
      <Box>
        {selectedTab === 0 && renderItems(carta)}
        {selectedTab === 1 && renderItems(bebidas)}
        {selectedTab === 2 && renderItems(menus)}
      </Box>

      <ModalCarta
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title={modalData.title}
        subcategories={modalData.subcategories}
      />
    </Box>
  );
};

export default NuestraCarta;
