import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

// Datos de testimonios
const testimonials = [
  {
    name: 'Carlos Gómez',
    date: '10 de enero, 2025',
    rating: 5,
    location: 'Madrid, España',
    text: 'Una experiencia única. Los sabores y la presentación de los platos son excepcionales.',
  },
  {
    name: 'Laura Sánchez',
    date: '8 de enero, 2025',
    rating: 4,
    location: 'Barcelona, España',
    text: 'Ambiente acogedor y servicio excelente. Sin duda, volveré pronto.',
  },
  {
    name: 'Diego López',
    date: '5 de enero, 2025',
    rating: 5,
    location: 'Sevilla, España',
    text: 'El mejor restaurante de la ciudad. Todo, desde el menú hasta la atención, fue perfecto.',
  },
];

// Componente principal
const Testimonios = () => {
  const theme = useTheme();

  // Generar estrellas de calificación
  const renderStars = (rating) => {
    return Array.from({ length: 5 }).map((_, index) =>
      index < rating ? (
        <StarIcon key={index} sx={{ color: theme.palette.primary.main }} />
      ) : (
        <StarBorderIcon key={index} sx={{ color: theme.palette.primary.main }} />
      )
    );
  };

  return (
    <Box
      sx={{
        padding: theme.spacing(10, 2),
        background: `linear-gradient(120deg, ${theme.palette.background.default}, ${theme.palette.background.paper})`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Título */}
      <Typography
        variant="h3"
        sx={{
          fontFamily: theme.typography.h1.fontFamily,
          fontWeight: theme.typography.h1.fontWeight,
          color: theme.palette.text.primary,
          marginBottom: theme.spacing(5),
        }}
      >
        Opiniones de nuestros clientes
      </Typography>

      {/* Grid de testimonios */}
      <Grid
        container
        spacing={4}
        sx={{
          maxWidth: '1200px',
          width: '100%',
        }}
      >
        {testimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
              elevation={3}
              sx={{
                padding: theme.spacing(4),
                borderRadius: theme.shape.borderRadius * 2,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: theme.shadows[3],
                '&:hover': {
                  boxShadow: theme.shadows[6],
                  transform: 'translateY(-5px)',
                },
                transition: 'all 0.3s ease',
              }}
            >
              {/* Nombre y fecha */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: theme.typography.fontWeightBold,
                  color: theme.palette.text.primary,
                  marginBottom: theme.spacing(1),
                }}
              >
                {testimonial.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.text.secondary,
                  marginBottom: theme.spacing(2),
                }}
              >
                {testimonial.date} - {testimonial.location}
              </Typography>

              {/* Calificación */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: theme.spacing(2),
                }}
              >
                {renderStars(testimonial.rating)}
              </Box>

              {/* Testimonio */}
              <Typography
                variant="body1"
                sx={{
                  fontStyle: 'italic',
                  color: theme.palette.text.secondary,
                }}
              >
                "{testimonial.text}"
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Testimonios;
