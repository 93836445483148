import React, { createContext, useContext, useMemo, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './styles/theme'; // Ajusta la ruta según tu estructura

const ThemeContext = createContext();

export const ThemeProviderCustom = ({ children }) => {
  const [mode, setMode] = useState('light'); // Estado inicial
  const [transitioning, setTransitioning] = useState(false); // Controlar transición

  const toggleTheme = () => {
    setTransitioning(true); // Iniciar transición
    setTimeout(() => {
      setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      setTransitioning(false); // Finalizar transición
    }, 300); // Duración de la animación
  };

  const currentTheme = useMemo(() => theme(mode), [mode]);

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme }}>
      <div
        style={{
          transition: 'background-color 0.3s ease, color 0.3s ease',
          backgroundColor: currentTheme.palette.background.default,
          color: currentTheme.palette.text.primary,
          minHeight: '100vh',
        }}
        className={transitioning ? 'theme-transition' : ''}
      >
        <ThemeProvider theme={currentTheme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </div>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProviderCustom');
  }
  return context;
};
