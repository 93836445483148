import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, useMediaQuery } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'; // Importamos useTranslation

const Slider = () => {
  const theme = useTheme();
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [loaded, setLoaded] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation(); // Hook de traducciones

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const logoVariants = {
    hidden: { opacity: 0, x: '-100vw', scale: 0.5, rotate: 0 },
    visible: {
      opacity: 1,
      x: 0,
      scale: 2,
      rotate: 180,
      transition: { duration: 2, ease: 'easeOut' },
    },
  };

  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
        width: '100%',
        height: { xs: '90vh', md: '100vh' },
        overflow: 'hidden',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        px: 2,
      }}
    >
      {/* Título principal traducido */}
      <motion.div
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0, transition: { duration: 1 } },
        }}
        style={{ textAlign: 'center', marginBottom: '20px' }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
          }}
        >
          {t('slider.title')} {/* Traducido */}
        </Typography>
      </motion.div>

      {/* Historia de Enigma traducida */}
      <motion.div
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 30 },
          visible: { opacity: 1, y: 0, transition: { duration: 1 } },
        }}
        style={{
          maxWidth: '700px',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' },
            fontWeight: 400,
            color: theme.palette.text.primary,
            lineHeight: 1.8,
            px: { xs: '10px', md: '20px' },
          }}
        >
          {t('slider.description')} {/* Traducido */}
        </Typography>
      </motion.div>

      {/* Botón interactivo traducido */}
      <motion.div
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, scale: 0.9 },
          visible: {
            opacity: 1,
            scale: 1,
            transition: { duration: 1, delay: 0.5 },
          },
        }}
        style={{ marginTop: '30px' }}
      >
        <Button
          component={Link}
          to="/about"
          variant="contained"
          sx={{
            padding: { xs: '8px 20px', sm: '12px 30px', md: '12px 40px' },
            fontSize: { xs: '0.9rem', sm: '1rem' },
            fontWeight: 'bold',
            borderRadius: '25px',
            textTransform: 'none',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            '&:hover': {
              backgroundColor: 'primary.dark',
              color: 'white',
            },
          }}
        >
          {t('slider.button')} {/* Traducido */}
        </Button>
      </motion.div>

      {/* Imagen decorativa */}
      <motion.img
        src="/assets/images/staketartar.png"
        alt="Stake Tartar"
        initial="hidden"
        animate={controls}
        variants={logoVariants}
        onLoad={() => setLoaded(true)}
        style={{
          position: 'absolute',
          top: loaded ? (isMobile ? '5%' : '10%') : (isMobile ? '15%' : '20%'),
          left: '80%',
          transform: 'translate(-70%, -10%)',
          zIndex: 1000,
          width: '100px',
          height: '100px',
        }}
      />

      {/* Fondo decorativo */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: { xs: '30%', md: '35%' },
          backgroundImage: 'url("/assets/images/hero-background2.jpg")',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          clipPath: 'ellipse(120% 50% at 50% 100%)',
        }}
      />
    </Box>
  );
};

export default Slider;
