const CategoryDetails = {
    Entrantes: {
      Croquetas: [
        { name: 'Ternera Estofada', description: 'Con setas y toque de Oporto', price: 2.70 },
        { name: 'Del Mar', description: 'Gambas, marisco y pescado', price: 2.70 },
        { name: 'Huerta', description: 'Espinacas, puerro y Gouda', price: 2.70 },
        { name: 'Pollo, Champiñones, Trufa y Parmesano', description: 'Pollo, champiñones, trufa y parmesano', price: 2.70 },
        { name: 'Cecina y Cebolla Caramelizada con PX', description: 'Cecina y cebolla caramelizada con PX', price: 2.70 },
        { name: 'Pulpo a la Gallega', description: 'Pulpo, sal y pimentón', price: 2.80 },
        { name: 'La Veggie', description: 'Verduras al curry', price: 2.60 },
      ],
      Entrantes: [
        { name: 'Stake Tartar de Solomillo', description: 'Con helado de parmesano, yema curada, anacardos y tomate seco', price: 17.50 },
        { name: 'Nuestras Bravas Caseras', description: 'Con salsa Chipotle y ají amarillo', price: 8.00 },
        { name: 'Tatín de Foie', description: 'Con hojaldre y manzana', price: 12.50 },
        { name: 'Corazones de Alcachofas', description: 'Rellenos de pollo y setas con espuma de carbonara', price: 14.50 },
        { name: 'Langostinos al Ajillo', description: 'Con portobellos', price: 14.50 },
        { name: 'Tartar de Salmón Ahumado', description: 'Sobre patacón de plátano, aguacate y mango', price: 17.50 },
        { name: 'Tataki de Atún', description: 'Sobre berenjena a la llama, salsa de sésamo y soja, y crujiente de arroz', price: 18.00 },
      ],
      Acompañantes: [
        { name: 'Pan', description: '', price: 1.20 },
        { name: 'Pan con Trío de Salsas', description: '', price: 2.20 },
        { name: 'Bowl de Patatas', description: '', price: 3.50 },
      ],
      "Cocas De Maíz": [
        { name: 'Pollo Tikka Massala', description: 'Con anacardos y col', price: 12.00 },
        { name: 'Langostinos Encevichados', description: 'Con guacamole, ají amarillo y cebolla encurtida', price: 12.50 },
        { name: 'Curry de Verduras', description: '', price: 11.50 },
      ],    
      Ensaladas: [
        { name: 'Ensalada de Burrata', description: 'Burrata, rúcula, pesto de almendras y tomates en texturas (opción sin gluten)', price: 14.00 },
        { name: 'Vitello Tonnato', description: 'Brotes verdes, salsa vitelo, tartar de atún y alcaparras fritas', price: 14.50 },
        { name: 'Tabuleh de Coliflor y Quinoa', description: 'Con verduras encurtidas, pasas, menta, falafel casero y salsa de pimientos asados', price: 12.50 },
      ],
    },
    "Plato Principal": {
      Pulpo: [
        { name: 'A Feira', description: 'Cocido de forma tradicional gallega, con cachelos', price: 18.00 },
        { name: 'Pulpo Estilo Enigma', description: 'Con emulsión de ajada gallega, boniato en texturas y salsa cremosa de tetilla', price: 18.50 },
        { name: 'Pulpo al Ajillo', description: 'Sobre puerro confitado, ajo negro y patata crujiente', price: 18.50 },
      ],
      "Platos Principales": [
        { name: 'Canelón de Pato XL', description: 'Con manzana, foie, reducción de oporto y frutos secos', price: 14.00 },
        { name: 'Curry Verde de Langostinos & Mejillones Salteados', description: 'Acompañado con pasta udon (opciones sin gluten y vegano)', price: 17.00 },
        { name: 'Risotto de Setas (Especialidad de la Cocinera)', description: 'Con parmesano y crema de trufa', price: 16.50 },
      ],
      Carnes: [
        { name: 'Jarrete de Cordero Estofado con Vino', description: 'Con frutos rojos, miel y especias, y puré de calabaza y canela', price: 24.90 },
        { name: 'Costillas de Ternera Cocinada a Baja Temperatura', description: 'En salsa oporto y puré de apio y miel', price: 24.00 },
        { name: 'Solomillo de Ternera Estilo Enigma', description: 'Con guarnición de temporada y boletus con cebolla', price: 26.00 },
      ],
      Pescados: [
        { name: 'Bacalao Frito en Harina de Maíz', description: 'Con puré de garbanzos, verdura salteada y romescu', price: 19.50 },
        { name: 'Lubina con Sabayón de Azafrán', description: 'Con vino y mejillones', price: 22.00 },
        { name: 'Taco de Atún Yakitori', description: 'Con puré de zanahoria y naranja, y crujiente de yuca', price: 21.00 },
      ],
    },
    "Postres Caseros": [
      { name: 'Crema Catalana de Maracuyá', description: 'Con helado de chocolate blanco', price: 7.50 },
      { name: 'Chocolate Enigma', description: '', price: 7.50 },
      { name: 'Tarta de Queso al Horno', description: 'Con mango estofado y helado', price: 7.50 },
      { name: 'Tiramisú', description: 'Con helado de café y crujiente de cacao', price: 7.50 },
      { name: 'Bizcocho Fluido de Turrón', description: 'Con helado de canela y crumble de almendra', price: 7.50 },
      { name: 'Sorbete de Fruta de la Pasión', description: 'Sobre cous cous de lima y vainilla (sin gluten)', price: 7.50 },
    ],
    Bebidas: {
      Aperitivos: [
        { name: 'Apperol Spritz', description: '', price: 9.00 },
        { name: 'Campari', description: '', price: 7.00 },
        { name: 'Campari Orange', description: '', price: 8.00 },
        { name: 'Ricard', description: '', price: 7.00 },
        { name: 'Sangria Copa', description: '', price: 6.00 },
        { name: 'Sangria Litro', description: '', price: 14.50 },
        { name: 'Tinto Verano Copa', description: '', price: 5.00 },
        { name: 'Tinto Verano Litro', description: '', price: 12.50 },
      ],
      Cervezas: [
        { name: 'Mahou de Barril', description: '', price: 2.50 },
        { name: 'Turia', description: '', price: 3.60 },
        { name: 'Alhambra', description: '', price: 3.50 },
        { name: 'Corona', description: '', price: 4.00 },
      ],
      Refrescos: [
        { name: 'Sprite', description: '', price: 2.50 },
        { name: 'Cola Zero', description: '', price: 2.90 },
        { name: 'Aquarius', description: '', price: 2.90 },
      ],
      Ginebras: [
        { name: 'Gin Mare', description: 'Con romero y tónica', price: 12.00 },
        { name: 'Hendrick\'s', description: 'Con uva y laurel', price: 12.00 },
        { name: 'Tanqueray', description: 'Con enebro y piel de lima', price: 10.00 },
      ],
    },
  };
  
  export default CategoryDetails;
  