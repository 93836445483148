import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MainHero from '../components/MainHero';
import Slider from '../components/Slider';
import NuestraCarta from '../components/NuestraCarta';
import ReservationForm from '../components/ReservationForm';
import Testimonios from '../components/Testimonios';
import FAQSection from '../components/FAQSection';
import RecipeCarousel from '../components/RecipeCarousel';
import SectionDivider from '../components/SectionDivider';

const Home = () => {
  const location = useLocation();

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // Si se ha pasado state para scroll, se hace scroll a esa sección.
    if (location.state && location.state.scrollTo) {
      scrollToSection(location.state.scrollTo);
      // Limpiar el state para no ejecutar el scroll en recargas posteriores.
      window.history.replaceState({}, document.title);
    } else {
      // Si no hay state, se posiciona la ventana en la parte superior.
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div>
      {/* Hero Section */}
      <MainHero
        onVerMenuClick={() => scrollToSection('nuestraCarta')}
        onReservarClick={() => scrollToSection('reservationForm')}
      />

      <SectionDivider backgroundColor="secondary.main" />
      <Slider />
      <SectionDivider backgroundColor="primary.main" invert />

      {/* Nuestra Carta Section */}
      <div id="nuestraCarta">
        <NuestraCarta />
      </div>

      <SectionDivider backgroundColor="secondary.main" />

      {/* Reservation Form Section */}
      <div id="reservationForm">
        <ReservationForm />
      </div>

      <SectionDivider backgroundColor="primary.main" invert />

      <Testimonios />

      <SectionDivider backgroundColor="secondary.main" />

      <FAQSection />

      <SectionDivider backgroundColor="primary.main" invert />

      <RecipeCarousel />

      <SectionDivider backgroundColor="secondary.main" />
    </div>
  );
};

export default Home;
