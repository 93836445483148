import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography, Collapse } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const faqs = [
  {
    question: "¿Qué tipo de cocina ofrece Enigma?",
    answer: "Enigma Cocina con Alma fusiona sabores auténticos con pasión, ofreciendo una experiencia culinaria única en el Casco Antiguo de Calpe.",
  },
  {
    question: "¿Cuáles son las especialidades de la casa?",
    answer: "Entre nuestras especialidades destacan el Pulpo Estilo Enigma, el Risotto de Setas y el Canelón de Pato XL.",
  },
  {
    question: "¿Ofrecen opciones vegetarianas o veganas?",
    answer: "Sí, contamos con diversas opciones vegetarianas y veganas para adaptarnos a tus necesidades.",
  },
  {
    question: "¿Es necesario realizar una reserva?",
    answer: "Recomendamos realizar una reserva para garantizar disponibilidad, especialmente en temporadas altas y fines de semana.",
  },
  {
    question: "¿Cuál es el horario de apertura?",
    answer: "Atendemos de miércoles a domingo de 13:00 a 16:00 y de 18:30 a 23:00, mientras que los martes abrimos de 18:30 a 23:00. Los lunes permanecemos cerrados.",
  },
  {
    question: "¿Dónde están ubicados?",
    answer: "Nos encontramos en Calle Justicia 6A, 03710 Calpe, Alicante.",
  },
  {
    question: "¿Ofrecen menús para niños?",
    answer: "Sí, disponemos de un menú infantil con opciones adaptadas para los más pequeños.",
  },
  {
    question: "¿Tienen opciones sin gluten?",
    answer: "Claro, muchas de nuestras opciones pueden prepararse sin gluten. Comunícanos tus necesidades al reservar.",
  },
  {
    question: "¿Cuál es la mejor manera de contactarlos?",
    answer: "Puedes llamarnos al (+34) 672 79 60 06 o escribirnos a isa@enigmaconalma.com.",
  },
];

const FAQSection = () => {
  const theme = useTheme();
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleExpand = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <Grid container spacing={3} sx={{ p: { xs: 2, sm: 4 }, maxWidth: '1200px', margin: '0 auto' }}>
      {faqs.map((faq, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            onClick={() => handleExpand(index)}
            sx={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: '12px',
              cursor: 'pointer',
              transition: 'transform 0.2s, box-shadow 0.2s',
              transform: expandedIndex === index ? 'scale(1.02)' : 'scale(1)',
              boxShadow: expandedIndex === index
                ? `0 4px 20px ${theme.palette.primary.main}`
                : theme.shadows[1],
              '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: `0 4px 15px ${theme.palette.primary.main}`,
              },
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  color: theme.palette.text.primary,
                  transition: 'text-shadow 0.2s',
                  textShadow: expandedIndex === index
                    ? `0px 0px 6px ${theme.palette.primary.light}`
                    : 'none',
                }}
              >
                {faq.question}
              </Typography>
              <Collapse in={expandedIndex === index}>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.text.secondary,
                    mt: 1,
                  }}
                >
                  {faq.answer}
                </Typography>
              </Collapse>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default FAQSection;
