import React, { useState } from 'react';
import {
  Card,
  CardMedia,
  Typography,
  Dialog,
  Box,
  IconButton,
  Fade,
  useMediaQuery,
} from '@mui/material';
import Slider from "react-slick";
import { useTheme } from '@mui/material/styles';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

const recipes = [
  {
    name: "Poke Mediterráneo de Pulpo y Granada",
    image: "/assets/images/poke.jpg",
    ingredients: [
      "200 g de pulpo cocido",
      "50 g de semillas de granada",
      "1 pepino, en rodajas",
      "50 g de queso feta desmenuzado",
      "2 cucharadas de aceite de oliva",
      "1 cucharada de jugo de limón",
      "Sal y pimienta al gusto"
    ],
    instructions: [
      "En un bol, mezcla el pulpo cocido, las semillas de granada, el pepino en rodajas y el queso feta.",
      "Aliña con aceite de oliva, jugo de limón, sal y pimienta. Mezcla bien.",
      "Sirve sobre una base de rúcula o espinacas frescas."
    ],
    tips: "Añade aguacate en rodajas para un toque cremoso y más nutritivo."
  },
  {
    name: "Gyozas de Hummus con Almendras y Pimiento Asado",
    image: "/assets/images/hummus.webp",
    ingredients: [
      "12 obleas de masa para gyozas",
      "150 g de hummus",
      "50 g de almendras picadas",
      "1 pimiento rojo asado, en tiras",
      "1 cucharada de aceite de oliva"
    ],
    instructions: [
      "Coloca una pequeña cantidad de hummus en el centro de cada oblea, añade almendras picadas y una tira de pimiento asado.",
      "Cierra las obleas formando gyozas, presionando los bordes para sellarlas.",
      "Cocina las gyozas al vapor durante 5 minutos o hasta que estén listas.",
      "Sirve con una salsa de yogur al ajo como acompañamiento."
    ],
    tips: "Dora las gyozas en una sartén con un poco de aceite después de cocinarlas al vapor para darles una textura crujiente."
  },
  {
    name: "Pizza de Harina de Garbanzo con Tzatziki y Verduras",
    image: "/assets/images/pizza.jpg",
    ingredients: [
      "150 g de harina de garbanzo",
      "200 ml de agua",
      "1 cucharadita de sal",
      "1 cucharada de aceite de oliva",
      "100 g de tzatziki",
      "1 calabacín, en rodajas finas",
      "50 g de aceitunas negras",
      "50 g de queso feta"
    ],
    instructions: [
      "Precalienta el horno a 200°C.",
      "Mezcla la harina de garbanzo, agua, sal y aceite hasta obtener una masa homogénea.",
      "Extiende la masa sobre una bandeja para hornear cubierta con papel de horno y hornea durante 15 minutos.",
      "Unta el tzatziki sobre la base horneada y añade las rodajas de calabacín, aceitunas y queso feta.",
      "Hornea otros 10 minutos hasta que las verduras estén tiernas."
    ],
    tips: "Decora con hierbas frescas como menta o perejil antes de servir."
  },
  {
    name: "Croquetas de Quinoa y Espinacas con Miel de Romero",
    image: "/assets/images/croquetas.jpeg",
    ingredients: [
      "200 g de quinoa cocida",
      "100 g de espinacas frescas picadas",
      "50 g de queso parmesano rallado",
      "2 huevos",
      "Pan rallado",
      "2 cucharadas de miel",
      "1 ramita de romero fresco"
    ],
    instructions: [
      "Mezcla la quinoa cocida, espinacas y queso parmesano hasta obtener una masa consistente.",
      "Forma pequeñas croquetas con la mezcla. Pásalas por huevo batido y luego por pan rallado.",
      "Fríe las croquetas en aceite caliente hasta que estén doradas. Retíralas y colócalas sobre papel absorbente.",
      "Calienta la miel con la ramita de romero para aromatizarla y sirve como acompañamiento de las croquetas."
    ],
    tips: "Para una versión más saludable, hornea las croquetas a 180°C durante 20 minutos en lugar de freírlas."
  },
  {
    name: "Ramen Mediterráneo con Caldo de Tomate y Albóndigas de Cordero",
    image: "/assets/images/albondigas.jpg",
    ingredients: [
      "500 ml de caldo de verduras",
      "200 ml de puré de tomate",
      "200 g de carne de cordero picada",
      "1 huevo",
      "Pan rallado",
      "200 g de fideos ramen",
      "Espinacas frescas"
    ],
    instructions: [
      "En un bol, mezcla la carne de cordero, el huevo y el pan rallado. Forma pequeñas albóndigas.",
      "Cocina las albóndigas en una sartén hasta que estén doradas.",
      "En una olla, mezcla el caldo de verduras con el puré de tomate. Lleva a ebullición y agrega las albóndigas.",
      "Cocina los fideos ramen según las instrucciones del paquete y agrégalos al caldo.",
      "Sirve el ramen con espinacas frescas como decoración."
    ],
    tips: "Añade un huevo poché al servir para un toque gourmet."
  },
  {
    name: "Tarta de Yogur Griego y Lavanda con Crocante de Nueces",
    image: "/assets/images/pastel.jpg",
    ingredients: [
      "150 g de galletas integrales",
      "50 g de mantequilla derretida",
      "200 g de yogur griego",
      "100 g de queso crema",
      "50 g de azúcar glas",
      "1 cucharadita de esencia de lavanda",
      "50 g de nueces picadas",
      "1 cucharada de miel"
    ],
    instructions: [
      "Tritura las galletas y mézclalas con la mantequilla derretida. Presiona en el fondo de un molde y refrigera 15 minutos.",
      "Bate el yogur, queso crema, azúcar glas y esencia de lavanda hasta obtener una mezcla homogénea. Vierte sobre la base.",
      "Refrigera al menos 2 horas. Antes de servir, decora con nueces y miel."
    ],
    tips: "Utiliza flores de lavanda comestibles para decorar y darle un toque sofisticado."
  }
];

const RecipeCarousel = () => {
  const [open, setOpen] = useState(false);
  const [currentRecipe, setCurrentRecipe] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Abrir modal
  const handleOpen = (recipe) => {
    setCurrentRecipe(recipe);
    setOpen(true);
  };

  // Cerrar modal
  const handleClose = () => {
    setOpen(false);
    setCurrentRecipe(null);
  };

  // Botones personalizados para el slider (sin sombra ni transformaciones exageradas)
  const NextArrow = ({ onClick }) => (
    <IconButton
      onClick={onClick}
      aria-label="Siguiente receta"
      sx={{
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-50%)',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': { backgroundColor: theme.palette.primary.dark },
        zIndex: 2,
      }}
    >
      <ArrowForwardIos fontSize="small" />
    </IconButton>
  );

  const PrevArrow = ({ onClick }) => (
    <IconButton
      onClick={onClick}
      aria-label="Receta anterior"
      sx={{
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': { backgroundColor: theme.palette.primary.dark },
        zIndex: 2,
      }}
    >
      <ArrowBackIos fontSize="small" />
    </IconButton>
  );

  // Configuración del slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerMode: true,
    centerPadding: "0px",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, centerPadding: "10px" },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1, centerPadding: "0px" },
      },
    ],
  };

  return (
    <>
      {/* Título central */}
      <Box sx={{ textAlign: 'center', my: 3, px: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: 700, color: theme.palette.text.primary }}>
          Descubre Nuevas Recetas
        </Typography>
        <Typography variant="subtitle2" sx={{ color: theme.palette.text.secondary, mt: 0.5 }}>
          Recetas fusión, dinámicas y fáciles de seguir.
        </Typography>
      </Box>

      {/* Carousel */}
      <Box sx={{ position: 'relative', width: '90%', mx: 'auto', overflow: 'hidden' }}>
        <Slider {...settings}>
          {recipes.map((recipe, index) => (
            <Box
              key={index}
              onClick={() => handleOpen(recipe)}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => e.key === 'Enter' && handleOpen(recipe)}
              // Se elimina el boxShadow en hover y se reduce el scale para evitar resaltes
              sx={{
                px: 1,
                transition: 'transform 0.3s ease',
                '&:hover, &:focus-visible': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              <Card
                sx={{
                  borderRadius: '16px',
                  overflow: 'hidden',
                  backgroundColor: theme.palette.background.paper,
                  width: '280px',
                  mx: 'auto',
                  boxShadow: 'none',
                }}
              >
                {/* Contenedor con proporción landscape (relación 4:3 ajustada a pt: '65%') */}
                <Box sx={{ position: 'relative', pt: '65%' }}>
                  <CardMedia
                    component="img"
                    image={recipe.image}
                    alt={recipe.name}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
                <Box sx={{ p: 0.5, textAlign: 'center' }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: theme.palette.text.primary }}>
                    {recipe.name}
                  </Typography>
                </Box>
              </Card>
            </Box>
          ))}
        </Slider>
      </Box>

      {/* Modal de receta */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        fullScreen={fullScreen}
        TransitionComponent={Fade}
        transitionDuration={300}
        PaperProps={{
          sx: {
            background: theme.palette.background.paper,
            color: theme.palette.text.primary,
            borderRadius: 2,
            p: 2,
          },
        }}
      >
        {currentRecipe && (
          <Box>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              {currentRecipe.name}
            </Typography>
            <Box sx={{ mb: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                Ingredientes:
              </Typography>
              <Typography variant="body2">
                {currentRecipe.ingredients.join(', ')}
              </Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                Instrucciones:
              </Typography>
              {currentRecipe.instructions.map((step, idx) => (
                <Typography key={idx} variant="body2" sx={{ mt: 0.5 }}>
                  {idx + 1}. {step}
                </Typography>
              ))}
            </Box>
            <Box>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                Consejos:
              </Typography>
              <Typography variant="body2">{currentRecipe.tips}</Typography>
            </Box>
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default RecipeCarousel;
