import React from 'react';
import { Box, Typography, Grid, IconButton, Link, useTheme } from '@mui/material';
import Slider from 'react-slick';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Footer = () => {
  const theme = useTheme();
  const location = useLocation();

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const testimonials = [
    '“Una experiencia culinaria inolvidable”',
    '“Sabores únicos, servicio excepcional”',
    '“El mejor restaurante de la región”',
  ];

  // Función para realizar scroll a la sección en Home
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(4),
        borderTop: `1px solid ${theme.palette.divider}`,
      }}
    >
      {/* Logo y Slider */}
      <Box
        sx={{
          textAlign: 'center',
          marginBottom: theme.spacing(4),
        }}
      >
        <Box
          component="img"
          src={
            theme.palette.mode === 'dark'
              ? '/assets/images/logo-blanco.png'
              : '/assets/images/logo-turquesa.png'
          }
          alt="Logo Enigma"
          sx={{
            width: '120px',
            margin: '0 auto 16px',
          }}
        />
        <Slider {...sliderSettings}>
          {testimonials.map((testimonial, index) => (
            <Typography
              key={index}
              variant="body1"
              sx={{
                fontStyle: 'italic',
                fontSize: '1.2rem',
                color: theme.palette.text.secondary,
              }}
            >
              {testimonial}
            </Typography>
          ))}
        </Slider>
      </Box>

      {/* Información del Footer */}
      <Box
        sx={{
          maxWidth: '1200px',
          margin: '0 auto',
          paddingX: theme.spacing(2),
        }}
      >
        <Grid container spacing={4}>
          {/* Horario */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ fontWeight: theme.typography.fontWeightBold }}>
              Nuestro Horario
            </Typography>
            <Typography variant="body2" sx={{ marginTop: theme.spacing(2) }}>
              Miércoles a Domingo: <br />
              13:00 - 16:00 y 18:30 - 23:00
            </Typography>
            <Typography variant="body2">Martes: 18:30 - 23:00</Typography>
            <Typography variant="body2">Lunes: Cerrado</Typography>
          </Grid>

          {/* Dirección */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ fontWeight: theme.typography.fontWeightBold }}>
              Dirección
            </Typography>
            <Typography variant="body2" sx={{ marginTop: theme.spacing(2) }}>
              Enigma Cocina con Alma <br />
              Calle Justicia 6A, <br />
              03710 Calpe, Alicante
            </Typography>
            <Typography variant="body2">
              Teléfono: <Link href="tel:+34672796006">(+34) 672 79 60 06</Link>
            </Typography>
            <Typography variant="body2">
              Email: <Link href="mailto:isa@enigmaconalma.com">isa@enigmaconalma.com</Link>
            </Typography>
          </Grid>

          {/* Redes Sociales */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ fontWeight: theme.typography.fontWeightBold }}>
              Síguenos
            </Typography>
            <Box sx={{ marginTop: theme.spacing(2) }}>
              <IconButton
                component="a"
                href="https://www.facebook.com/enigma.restaurante.calpe"
                target="_blank"
                aria-label="Facebook"
                sx={{ color: theme.palette.primary.main }}
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                component="a"
                href="https://www.tripadvisor.es/Restaurant_Review-g187526-d23958723-Reviews-Enigma_Cocina_Con_Alma-Calpe_Costa_Blanca_Province_of_Alicante_Valencian_Communi.html?m=19905"
                target="_blank"
                aria-label="Tripadvisor"
                sx={{ color: theme.palette.primary.main }}
              >
                <TravelExploreIcon />
              </IconButton>
              <IconButton
                component="a"
                href="https://www.instagram.com/enigmaconalma"
                target="_blank"
                aria-label="Instagram"
                sx={{ color: theme.palette.primary.main }}
              >
                <InstagramIcon />
              </IconButton>
            </Box>
          </Grid>

          {/* Enlaces Directos */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ fontWeight: theme.typography.fontWeightBold }}>
              Enlaces Directos
            </Typography>
            <Box sx={{ marginTop: theme.spacing(2), display: 'flex', flexDirection: 'column' }}>
              <Link
                component={RouterLink}
                to="/PrivacyPolicy"
                sx={{
                  color: theme.palette.text.secondary,
                  textDecoration: 'none',
                  marginBottom: theme.spacing(1),
                  '&:hover': { color: theme.palette.primary.main },
                }}
              >
                Política de Privacidad
              </Link>
              <Link
                component={RouterLink}
                to="/about"
                sx={{
                  color: theme.palette.text.secondary,
                  textDecoration: 'none',
                  marginBottom: theme.spacing(1),
                  '&:hover': { color: theme.palette.primary.main },
                }}
              >
                Contacto
              </Link>
              <Link
                component={RouterLink}
                to="/"
                onClick={(e) => {
                  if (location.pathname === '/') {
                    e.preventDefault(); // Evitar redireccionamiento si ya está en la página de inicio
                    scrollToSection('reservationForm');
                  }
                }}
                sx={{
                  color: theme.palette.text.secondary,
                  textDecoration: 'none',
                  marginBottom: theme.spacing(1),
                  '&:hover': { color: theme.palette.primary.main },
                }}
              >
                Reservas
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Copyright */}
      <Box
        sx={{
          marginTop: theme.spacing(4),
          textAlign: 'center',
          backgroundColor: theme.palette.background.paper,
          paddingY: theme.spacing(2),
        }}
      >
        <Typography variant="body2">
          © {new Date().getFullYear()} Enigma Cocina con Alma. Todos los derechos reservados.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
