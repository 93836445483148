import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  Typography,
  Box,
  IconButton,
  Grid,
  Card,
  CardContent,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ModalCarta = ({ open, onClose, title, subcategories }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme(); // Accede al tema actual

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Validar si subcategories es un objeto con subcategorías o directamente una lista de elementos
  const isSubcategory =
    subcategories && typeof subcategories === 'object' && !Array.isArray(subcategories);

  // Si es subcategoría, extraemos las claves, si no, asumimos que es una lista directa de elementos
  const subcategoryKeys = isSubcategory ? Object.keys(subcategories) : [];

  // Si es subcategoría, se selecciona el grupo de elementos correspondientes a la subcategoría actual
  const selectedItems = isSubcategory
    ? subcategories?.[subcategoryKeys[selectedTab]] || [] // Acceder a las subcategorías
    : subcategories || []; // Si no es subcategoría, se usa directamente la lista de elementos

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      {/* Encabezado del Modal */}
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          textAlign: 'center',
          fontWeight: 'bold',
          position: 'relative',
          paddingY: 2,
          borderBottom: `3px solid ${theme.palette.divider}`,
        }}
      >
        {title}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: theme.palette.primary.contrastText,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Contenido del Modal */}
      <DialogContent
        sx={{
          backgroundColor: theme.palette.background.default,
          padding: 3,
        }}
      >
        {isSubcategory ? (
          <>
            {/* Tabs */}
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              centered
              TabIndicatorProps={{
                style: { backgroundColor: theme.palette.primary.main },
              }}
              sx={{
                marginBottom: 3,
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontWeight: 'bold',
                  color: theme.palette.text.secondary,
                },
                '& .Mui-selected': {
                  color: theme.palette.primary.main,
                },
              }}
            >
              {subcategoryKeys.map((subcategory, index) => (
                <Tab key={index} label={subcategory} />
              ))}
            </Tabs>
            <Box mt={2}>
              {selectedItems.length > 0 ? (
                <Grid container spacing={3}>
                  {selectedItems.map((item, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <Card
                        sx={{
                          borderRadius: '15px',
                          boxShadow: theme.shadows[3],
                          background: theme.palette.background.paper,
                          transition: 'transform 0.2s',
                          '&:hover': {
                            transform: 'translateY(-5px)',
                          },
                        }}
                      >
                        <CardContent
                          sx={{
                            textAlign: 'center',
                            padding: 3,
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 'bold',
                              color: theme.palette.text.primary,
                              marginBottom: 1,
                            }}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: theme.palette.text.secondary,
                              marginBottom: 2,
                            }}
                          >
                            {item.description || 'Sin descripción'}
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 'bold',
                              color: theme.palette.mode === 'dark' 
                                ? theme.palette.warning.main // Color naranja en modo oscuro
                                : theme.palette.primary.main, // Color primario en modo claro
                            }}
                          >
                            {item.price}€
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography
                  variant="body1"
                  textAlign="center"
                  mt={2}
                  sx={{
                    color: theme.palette.text.secondary,
                  }}
                >
                  No hay elementos disponibles en esta subcategoría.
                </Typography>
              )}
            </Box>
          </>
        ) : (
          <Box mt={2}>
            {/* Renderizar elementos directamente si no hay subcategorías */}
            {selectedItems.length > 0 ? (
              <Grid container spacing={3}>
                {selectedItems.map((item, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Card
                      sx={{
                        borderRadius: '15px',
                        boxShadow: theme.shadows[3],
                        background: theme.palette.background.paper,
                        transition: 'transform 0.2s',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                        },
                      }}
                    >
                      <CardContent
                        sx={{
                          textAlign: 'center',
                          padding: 3,
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 'bold',
                            color: theme.palette.text.primary,
                            marginBottom: 1,
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: theme.palette.text.secondary,
                            marginBottom: 2,
                          }}
                        >
                          {item.description || 'Sin descripción'}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 'bold',
                            color: theme.palette.mode === 'dark' 
                              ? theme.palette.warning.main // Color naranja en modo oscuro
                              : theme.palette.primary.main, // Color primario en modo claro
                          }}
                        >
                          {item.price}€
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography
                variant="body1"
                textAlign="center"
                mt={2}
                sx={{
                  color: theme.palette.text.secondary,
                }}
              >
                No hay elementos disponibles para esta categoría.
              </Typography>
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalCarta;
