import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import MapWithRoutes from '../components/MapWithRoutes';

const Contact = () => {
  return (
    <Container sx={{ paddingTop: '40px', paddingBottom: '40px' }}>
      {/* Historia de Enigma */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <Typography
          variant="h3"
          component="h1"
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: '20px',
            color: 'primary.main',
          }}
        >
          Nuestra Historia
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: 'center',
            marginBottom: '40px',
            color: 'text.primary',
            lineHeight: 1.8,
          }}
        >
          Enigma es más que un restaurante; es un lugar donde la pasión por la gastronomía
          se encuentra con un diseño único. Desde nuestra apertura, nos hemos esforzado por
          ofrecer una experiencia que combine sabores tradicionales con un toque moderno.
        </Typography>
      </motion.div>

      {/* Collage de Imágenes */}
      <Box sx={{ marginBottom: '40px' }}>
        <Typography
          variant="h4"
          component="h2"
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: '20px',
            color: 'secondary.main',
          }}
        >
          Galería
        </Typography>
        <Grid container spacing={2}>
          {/* Primera fila con 2 imágenes */}
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
                borderRadius: '12px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                aspectRatio: '4 / 3', // Mantener proporción 4:3
              }}
            >
              <img
                src="/assets/images/croquetas.png"
                alt="Restaurante 1"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
                borderRadius: '12px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                aspectRatio: '4 / 3',
              }}
            >
              <img
                src="/assets/images/isa.jpg"
                alt="Restaurante 2"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Grid>

          {/* Segunda fila con 3 imágenes */}
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
                borderRadius: '12px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                aspectRatio: '4 / 3',
              }}
            >
              <img
                src="/assets/images/vino.jpg"
                alt="Restaurante 3"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
                borderRadius: '12px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                aspectRatio: '4 / 3',
              }}
            >
              <img
                src="/assets/images/terrace.jpg"
                alt="Terraza"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
                borderRadius: '12px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                aspectRatio: '4 / 3',
              }}
            >
              <img
                src="/assets/images/exterior.jpg"
                alt="Exterior"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Mapa Interactivo */}
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 1, duration: 1 }}
      >
        <Typography
          variant="h4"
          component="h2"
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: '20px',
            color: 'secondary.main',
          }}
        >
          Encuéntranos
        </Typography>
        <Box
          sx={{
            marginTop: '20px',
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
          }}
        >
          <MapWithRoutes />
        </Box>
      </motion.div>
    </Container>
  );
};

export default Contact;
