import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const CookiesConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookiesConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesConsent', 'true');
    setIsVisible(false);
  };

  return (
    isVisible && (
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          backgroundColor: 'primary.main',
          color: 'white',
          padding: '20px',
          zIndex: 1000,
          textAlign: 'center',
        }}
      >
        <Typography variant="body1" sx={{ marginBottom: '10px' }}>
          Usamos cookies para mejorar tu experiencia en nuestro sitio web.{' '}
          <Link
            to="/privacy-policy"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            Leer más
          </Link>
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAccept}
          sx={{ fontWeight: 'bold' }}
        >
          Aceptar
        </Button>
      </Box>
    )
  );
};

export default CookiesConsent;
