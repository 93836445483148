import React from 'react';
import { Box, Typography, Container, List, ListItem, ListItemText } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ padding: '40px 20px' }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          color: 'primary.main',
          marginBottom: '20px',
          textAlign: 'center',
        }}
      >
        Política de Privacidad
      </Typography>

      <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
        En **Enigma Cocina con Alma**, respetamos y valoramos tu privacidad. Esta Política de
        Privacidad explica cómo recopilamos, usamos y protegemos tu información personal conforme
        al Reglamento General de Protección de Datos (GDPR) y otras normativas aplicables.
      </Typography>

      <Typography variant="h6" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
        1. Información que recopilamos
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
        Podremos recopilar las siguientes categorías de datos personales cuando interactúas con
        nosotros:
      </Typography>
      <List sx={{ marginBottom: '20px', pl: 2 }}>
        <ListItem>
          <ListItemText primary="Datos de contacto: Nombre, dirección de correo electrónico y número de teléfono." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Datos de reserva: Fecha, hora, preferencias alimentarias y número de comensales." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Datos técnicos: Dirección IP, tipo de navegador, dispositivo y datos de cookies." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Otros datos proporcionados voluntariamente, como consultas o comentarios." />
        </ListItem>
      </List>

      <Typography variant="h6" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
        2. Cómo usamos tu información
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
        La información recopilada se utiliza para:
      </Typography>
      <List sx={{ marginBottom: '20px', pl: 2 }}>
        <ListItem>
          <ListItemText primary="Gestionar tus reservas y personalizar tu experiencia." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Cumplir con nuestras obligaciones legales y contractuales." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Comunicarnos contigo para responder consultas o enviarte información relevante." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Analizar datos para mejorar nuestros servicios y sitio web." />
        </ListItem>
      </List>

      <Typography variant="h6" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
        3. Base legal para el tratamiento de datos
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
        Procesamos tus datos personales en base a las siguientes bases legales:
      </Typography>
      <List sx={{ marginBottom: '20px', pl: 2 }}>
        <ListItem>
          <ListItemText primary="Consentimiento explícito cuando nos proporcionas tus datos." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Cumplimiento de una obligación legal o regulatoria." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Ejecución de un contrato, como gestionar una reserva." />
        </ListItem>
      </List>

      <Typography variant="h6" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
        4. Compartir tu información
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
        Nunca vendemos tu información personal. Sin embargo, podemos compartirla con:
      </Typography>
      <List sx={{ marginBottom: '20px', pl: 2 }}>
        <ListItem>
          <ListItemText primary="Proveedores de servicios externos, como plataformas de reservas, siempre bajo estrictas condiciones de privacidad." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Autoridades legales, si estamos obligados por ley a compartir dicha información." />
        </ListItem>
      </List>

      <Typography variant="h6" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
        5. Derechos del usuario
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
        Según el GDPR, tienes los siguientes derechos:
      </Typography>
      <List sx={{ marginBottom: '20px', pl: 2 }}>
        <ListItem>
          <ListItemText primary="Acceso: Puedes solicitar una copia de tus datos personales." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Rectificación: Puedes corregir datos inexactos o incompletos." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Supresión: Puedes solicitar que eliminemos tus datos personales, salvo que exista una obligación legal para conservarlos." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Oposición: Puedes oponerte al tratamiento de tus datos en ciertos casos." />
        </ListItem>
      </List>

      <Typography variant="h6" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
        6. Cookies y tecnologías similares
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
        Utilizamos cookies para analizar el tráfico del sitio y personalizar tu experiencia.
        Puedes administrar tus preferencias de cookies desde la configuración de tu navegador.
      </Typography>

      <Typography variant="h6" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
        7. Cambios en esta política
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
        Esta política puede ser modificada ocasionalmente para reflejar cambios en nuestros servicios
        o en la normativa aplicable. Te notificaremos de los cambios importantes antes de que entren en vigor.
      </Typography>

      <Typography variant="h6" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
        8. Contacto
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
        Si tienes preguntas o inquietudes sobre esta Política de Privacidad, puedes ponerte en
        contacto con nosotros en:
        <br />
        Email: isa@enigmaconalma.com
        <br />
        Teléfono: +34 672 79 60 06
        <br />
        Dirección: Calle Justicia 6A, 03710 Calpe, Alicante, España.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
