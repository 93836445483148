import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translate/translation-en.json';
import es from './translate/translation-es.json';
import nl from './translate/translation-nl.json';
import fr from './translate/translation-fr.json';
import de from './translate/translation-de.json';

const resources = {
  en: { translation: en },
  es: { translation: es },
  nl: { translation: nl },
  fr: { translation: fr },
  de: { translation: de },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'es', // Idioma inicial
    fallbackLng: 'en', // Idioma de respaldo
    interpolation: {
      escapeValue: false, // React ya escapa valores por defecto
    },
  });

export default i18n;
